:root {
  --background-color-blue: #23cdcd;
  --background-color-white: #ffffff;
  --background-color-pink: #23cdcd;

  --gradient-main-lighter: linear-gradient(to right bottom, #ff6858 50%, #ff867a 90%);
  --background-gradient-main: var(--key-color);

  --main-text-color: #787878;
  --off-text-color: #ffffff;
  --disabled-text-color: rgba(0, 0, 0, 0.1);

  --menu-color: #051e34;

  --main-button-color: #1b72e8;
  --main-button-text-color: #ffffff;
  --disabled-main-button-color: rgba(0, 0, 0, 0.1);

  --key-color: #ff6f60;
  --key-color-2: #7f65ff;
  --key-color-2-gradient: linear-gradient(91.82deg, #765cf6 3.83%, #a694ff 98.41%);

  --accent-color: #f8f59d;
  --background-color: #f9fbfc;

  --blue: #6dcff6;
  --purple: #a793eb;
  --orange: #ffaf7a;
  --teal: #59d8b9;

  --grey: #404040;
  --grey-1: #666666;
  --grey-2: #aaaaaa;
  --grey-3: #c4c4c4;
  --grey-4: #d9d9d9;
  --grey-5: #f2f2f2;

  --green: #59d87f;
  --yellow: #ffd03a;
  --red: #ff4444;

  --status-red: #ff5c50;
  --status-yellow: #ffca20;
  --status-green: #42ce6c;

  --red-gradient: linear-gradient(94.78deg, #ff6f60 0%, #ff9b90 100%);
  --green-gradient: linear-gradient(93.41deg, #59d8b9 1.21%, #89e1cc 95.08%);
  --yellow-gradient: linear-gradient(94.78deg, #ffca20 0%, #ffda63 100%);
  --yellow-gradient-2: linear-gradient(94.78deg, #feb98ab8 0%, #ffc963 100%);
  --blue-gradient: linear-gradient(94.78deg, #23cdcd 0%, #23cdcd7a 100%);

  --sunset-gradient: linear-gradient(93.91deg, #ffaf7a 0.55%, #ffc9a5 98.63%);
  --ocean-gradient: linear-gradient(93.41deg, #59d8b9 1.21%, #89e1cc 95.08%);
  --salmon-gradient: linear-gradient(93.41deg, #fc909f 1.21%, #ffb1bc 95.08%);
  --comp-gradient: linear-gradient(94.78deg, #ff6f60 0%, #ff9b90 100%);

  --box-shadow-1: 0px 0px 7px rgb(0 0 0 / 10%);
}

body {
  width: 100%;
  background: var(--background-color);
  color: var(--grey);
  font-family: montserrat;
  font-size: 16px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

textarea,
input,
select {
  font-weight: 500;
  font-family: montserrat;
}

img {
  display: block;
  max-width: 100%;
}

button {
  outline: none;
  cursor: pointer;
  font-family: montserrat;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body li {
  list-style: none;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 32px;
  padding: 0.5em;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 16px;
}

body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* 
*  Needed for accesibility, should be atleast used in links and buttons that don't
*  have text inside them.
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.active {
  transition: 0.4s;
  cursor: pointer;
  color: var(--menu-color);
  padding: 0.2em !important;
  border-left: 5px solid;
  border-right: 5px solid;
  border-color: var(--background-color-blue);
  transition: 0.4s;
}

.active-link,
.active-link svg,
.active-link p,
.active-link a {
  color: var(--key-color-2) !important;
  transform: scale(1);
  pointer-events: none;
  transition: 0.4s;
}

#SvgjsText1038 {
  font-weight: bold !important;
  font-family: "Montserrat, sans-serif";
}

#SvgjsText1040 {
  font-weight: bold !important;
  font-family: "Montserrat, sans-serif";
}

#SvgjsText1042 {
  font-weight: bold !important;
  font-family: "Montserrat, sans-serif";
}

#SvgjsText1093 {
  font-weight: bold !important;
  font-family: "Montserrat, sans-serif";
}

#SvgjsText1095 {
  font-weight: bold !important;
  font-family: "Montserrat, sans-serif";
}

#SvgjsText1097 {
  font-weight: bold !important;
  font-family: "Montserrat, sans-serif";
}

.apexcharts-xaxis-label {
  font-weight: bold !important;
  font-family: "Montserrat, sans-serif";
}
